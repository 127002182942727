import React, { useEffect } from 'react';

const DeviceFingerPrint = ({ gatewayConfig, sessionId }) => {
  useEffect(() => {
    if (!gatewayConfig.orgId || !gatewayConfig.gatewayMerchantId || !sessionId) {
      return;
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `${gatewayConfig.fingerPrintUrl}?org_id=${gatewayConfig.orgId}&session_id=${gatewayConfig.gatewayMerchantId}${sessionId}`;
    script.async = true;
    const noscript = document.createElement("noscript");
    noscript.innerHTML = `
      <iframe 
        style="width: 100px; height: 100px; border: 0; position: absolute; top: -5000px;" 
        src="${gatewayConfig.fingerPrintUrl}?org_id=${gatewayConfig.orgId}&session_id=${gatewayConfig.gatewayMerchantId}${sessionId}">
      </iframe>
    `;
    document.head.appendChild(script);
    document.body.appendChild(noscript);

    return () => {
      document.head.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, [gatewayConfig, sessionId]);

  return (
    <div></div>
  );
};

export default DeviceFingerPrint;