export const routePaths = {
  HOME: "/", //dashboard
  USAGE: "/usage-stats", //usage component
  SELF_HELP: "/self-help", //Selfhelp component
  TARIFF: "/tariff", //Tariff Component
  LOGOUT: "/logout", //logout
  CARDS: "/cards", //Cards - Dev Only Component
  RES: "/responsive", //Responsive - Dev Only Component
  PAYMENT_RECEIPT:"/payment-receipt",//Payment receipt
  BILLING: "/billing", //Billing Component
  GET_SUPPORT: "/get-support", // get support ticket
  PROFILE: "/profile", // get profile
  INITIATE_PAYMENT: "/initiate-payment", // Initiate Payment Component
  BILL_DETAILS: "/bill-details", //Bill Details
  PAYMENT_CONFIRMATION: "/payment", //Payment Confirmation Component
  RETRY: "/service-unavailable",
  GOOGLE_PAY_CONFIRMATION: "/confirmation",
  GOOGLE_PAY_PROCESS: "/gpay-process",
  GOOGLE_PAY_3DS_RETURN_URL: "/gpay-3ds",
  GOOGLE_PAY_3DS_RESULT_URL: "/gpay-3ds-result",
  // CONTACTS: "/contacts", //Contacts Component
};
