import React, { useRef, useState, useEffect } from "react"; import { Grid, Typography, Button, Table, TableRow, TableCell, TableBody, Hidden, Card, } from "@material-ui/core";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Backlink from "../../self-help/backlink";
import Skeleton from '@material-ui/lab/Skeleton';
import { useStyles } from "../../app/base-style";
import { routePaths } from "../../config/route-paths";
import ReactToPrint from "react-to-print";
import { customStyles } from "../multiple-payment/initiate-payment-style";
import { appService } from "../../../services/app.service";
import { commonService } from "../../../services/common.service";
import headerLogo from "../../../assests/images/qc-default-logo.png";
import { store } from "../../../helpers/store";
import { Link } from "react-router-dom";
import { history } from "../../../helpers/history";
import "../../app/base-style.css";
import "../payment-receipt.css";
import { config } from "../../../enviroment";
import { ticketAction } from "../../../actions/ticket.action";
import { connect, useSelector } from "react-redux";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { accountDetailsWithApiCall } from "../../../actions/home.action";

export const customUseStyles = makeStyles(theme => createStyles({
    container: {
      minHeight: "50vh", 
      backgroundColor: "white",
      borderRadius: "10px"
    },
  }));
const GooglePayConfirmation = ({ accountDetailsWithApiCall }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [data, setMainData] = useState();
    const location = useLocation();
    const { paymentData, orderDetails, transactionInfo, referenceId, deviceInfo, clientReferenceInformation, consumerAuthenticationInformation, type } = location.state || {};
    let labels = {}, connectionLabel, receiptLabel, labelsHide, labelsDetails
    if (appService.getLabels()) {
        labels = appService.getLabels().getLabels.PAYMENT_CONFIRMATION;
        connectionLabel = appService.getLabels().getLabels.BILL_DETAILS
            .CONNECTION_DETAILS;
        receiptLabel = appService.getLabels().getLabels.PAYMENT_RECEIPT
            .PAYMENT_RECEIPT_PAY_ID;
        labelsHide = store.getState().labels.data.getLabels.USER_MANAGEMENT
            .QUICK_PAY_HIDE;
        labelsDetails = store.getState().labels.data.getLabels.USER_MANAGEMENT
            .QUICK_PAY_DETAILS;
    }
    let multipleAccountDetails = "";
    const accountInformation = store.getState().meterDetails;
    if (accountInformation) {
        multipleAccountDetails = accountInformation;
    }
    const [open, setOpen] = useState(false);
    const [postPaymentProcessing, setPostPaymentProcessing] = useState(false);
    const classes = useStyles();
    let headStyle = `tableBorder ${classes.h3Primary}`;
    const [appLogo, setAppLogo] = useState(null);
    const componentRef = useRef();
    const customStyle = customStyles();
    const customUseStyle = customUseStyles();
    const app_Logo = useSelector((state) => state?.appLogo);
    useEffect(() => {
        setAppLogo(app_Logo);
    }, [app_Logo]);

    const handleClicked = () => {
        if (open === true) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };
    useEffect(() => {
        const processPayment = async () => {
            try {
                let body = {
                    paymentData, transactionInfo, deviceInfo, paymentInfo: { referenceId: referenceId, type, clientReferenceInformation, consumerAuthenticationInformation }
                }
                if(type === "AUTHORIZED") {
                    setPostPaymentProcessing(orderDetails);
                } else {
                    if (!paymentData) throw new Error('No payment data found.');
                    const response = await axios.post(config.urls.USAGE_SERVICE_URL + '/google-pay/process', body);
                    if (response.data) {
                        if(response.data.status == "AUTHORIZED_RISK_DECLINED") {
                            setError("Your payment transaction is declined.");
                        } else if (response.data.status == "AUTHORIZED") {
                            setMainData(response.data)
                            setPostPaymentProcessing(response.data.orderDetails);
                            accountDetailsWithApiCall();
                        } else if (response.data.flow === "CAPTURE" || response.data.flow === "PENDING") {
                            setMainData(response.data)
                            setPostPaymentProcessing(response.data.orderDetails);
                            accountDetailsWithApiCall();
                        } else {
                            setMainData(response.data)
                        }
                    }
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Payment Error:', error);
                setError("Something went wrong.");
                setIsLoading(false);
            }
        };
        if(paymentData && transactionInfo) {
            window.history.replaceState({}, document.title, window.location.pathname);
            processPayment();
        } else {
            history.push({
              pathname: routePaths.INITIATE_PAYMENT,
              state: { paymentData, transactionInfo } 
            });
        }
    }, [paymentData]);
    return (
    <Card style={{ width: "100%", padding: "10px" }}>
        <div style={{width: "100%"}}>
            {isLoading || !labels ? (
                <>
                    <Grid container alignItems="center" className={customUseStyle.container}>
                        <Grid item lg={12} md={12} sm={12}>
                            <Typography variant='subtitle2' style={{ textAlign: "center" }}>
                            Processing your payment...
                            </Typography>
                            <Typography variant='subtitle2' style={{ textAlign: "center" }}>
                            Please don't refresh the page or navigate other page.
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            ) : error || !postPaymentProcessing?.mainData  ? (
                <Grid container alignItems="center" className={customUseStyle.container}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Typography variant='subtitle2' style={{ textAlign: "center" }}>
                            <b>{error}</b>
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <div>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item xs={1}>
                            <Backlink backToHome={true} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Hidden xlUp xsDown>
                                <Typography
                                    color="primary"
                                    gutterBottom
                                    variant="h6"
                                    component="h2"
                                    align="center"
                                >
                                    <b>{labels.PAYMENT_CONFIRMATION_HEADER}</b>
                                </Typography>
                            </Hidden>
                            <Hidden smUp>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    className="margin-top-10"
                                    spacing={1}
                                >
                                    <Grid item>
                                        <Typography
                                            color="primary"
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                            align="center"
                                        >
                                            <b>{labels.PAYMENT_CONFIRMATION_HEADER}</b>
                                            &nbsp;&nbsp;
                                            <Link onClick={handleClicked}>
                                                {open ? (
                                                    <span className={`dividerCenterCss ${classes.linkColor}`}>
                                                        {labelsHide} <ExpandLess />
                                                    </span>
                                                ) : (
                                                    <span className={`dividerCenterCss ${classes.linkColor}`}>
                                                        {labelsDetails} <ExpandMore />
                                                    </span>
                                                )}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                        <Grid item md={1}>
                            <div>&nbsp;&nbsp;</div>
                        </Grid>
                    </Grid>

                    <div className={customStyle.printDisplay}>
                        <div ref={componentRef} className={customStyle.printSize}>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justify="space-between"
                            >
                                <Grid item>
                                    <img
                                        height="4%"
                                        width="130%"
                                        src={appLogo ? appLogo.assetPath : headerLogo}
                                        alt={
                                            appLogo
                                                ? labels[appLogo.fallBackLabelKey]
                                                : labels.ASSET_LOGO_FALLBACK_TEXT
                                        }
                                        loading="lazy"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        color="primary"
                                        gutterBottom
                                        variant="h6"
                                        component="h2"
                                        align="center"
                                    >
                                        <b>{labels.PAYMENT_CONFIRMATION_PRINT_TITLE}</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <div>&nbsp;&nbsp;</div>
                                </Grid>
                            </Grid>
                            <br />
                            <div className="text">
                                <span className={customStyle.greyColor}>
                                    {labels.PAYMENT_CONFIRMATION_SUBTITLE}&nbsp;
                                    <span className={customStyle.linkColor}>
                                        <b> {labels.PAYMENT_CONFIRMATION_FAQ}</b>
                                    </span>
                                </span>
                            </div>
                            <hr />
                            {postPaymentProcessing.mainData.length > 0
                                ? postPaymentProcessing.mainData.map((element, k) => {
                                    return (
                                        <React.Fragment key={k}>
                                            <Grid container direction="row" justify="center">
                                                <Grid item xs={12} md={6}>
                                                    <br />
                                                    <Typography className={classes.h2Primary}>
                                                        {postPaymentProcessing.mainData.length > 1 ? (
                                                            <b>
                                                                {labels.PAYMENT_CONFIRMATION_INFO_TITLE +
                                                                    `(${k + 1}/${postPaymentProcessing.mainData.length
                                                                    })`}
                                                            </b>
                                                        ) : (
                                                            <b>{labels.PAYMENT_CONFIRMATION_INFO_TITLE}</b>
                                                        )}
                                                    </Typography>
                                                    <br />
                                                    {postPaymentProcessing ? (
                                                        <Table size="small">
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell className={headStyle}>
                                                                        <b>{labels.PAYMENT_CONFIRMATION_INFO_TYPE}</b>
                                                                    </TableCell>
                                                                    <TableCell className={headStyle} align="left">
                                                                        {element.paymentInfo.input.paymentType}
                                                                    </TableCell>
                                                                </TableRow>
                                                                {element.paymentInfo.input.creditCardInfo
                                                                    .cardType &&
                                                                    element.paymentInfo.input.creditCardInfo
                                                                        .cardNumber ? (
                                                                    <TableRow>
                                                                        <TableCell className={headStyle}>
                                                                            <b>
                                                                                {
                                                                                    labels.PAYMENT_CONFIRMATION_INFO_CARD_DETAILS
                                                                                }
                                                                            </b>
                                                                        </TableCell>
                                                                        <TableCell className={headStyle} align="left">
                                                                            {element.paymentInfo.input.creditCardInfo
                                                                                .cardType +
                                                                                ":" +
                                                                                element.paymentInfo.input.creditCardInfo
                                                                                    .cardNumber}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ) : null}
                                                                {element.billingAddress &&
                                                                    <TableRow>
                                                                        <TableCell className={headStyle}>
                                                                            <b>{labels?.PAYMENT_BILLING_ADDRESS}</b>
                                                                        </TableCell>
                                                                        <TableCell className={headStyle} align="left">
                                                                            {element.billingAddress}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    ) : null}
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Card className={customStyle.lightblueColorPayment}>
                                                        <Typography className={classes.h3Primary}>
                                                            <b>{connectionLabel}</b>
                                                        </Typography>
                                                        <Typography className={classes.h3Primary}>
                                                            {element.head.connectionId}
                                                            <br />
                                                        </Typography>
                                                        <br />
                                                        {element.errorInformation.isInError === "true" ? null : (
                                                            <React.Fragment>
                                                                <Typography className={classes.h3Primary}>
                                                                <b>{receiptLabel}</b>
                                                                </Typography>
                                                                <Typography className={classes.h3Primary}>
                                                                    {element.paymentInfo.inputOutput && element.paymentInfo.inputOutput.paymentEventId }
                                                                <br />
                                                                </Typography>
                                                                <br />
                                                            </React.Fragment>
                                                        )}
                                                        <Typography className={classes.h3Primary}>
                                                            <b>{labels.PAYMENT_CONFIRMATION_PAYMENT_STATUS}</b>
                                                        </Typography>
                                                        <Typography className={classes.h3Primary}>
                                                            {
                                                                element.errorInformation.isInError === "true" ? (
                                                                    <span className="redColor">
                                                                        Failure - {element.errorInformation.errorMessage}
                                                                    </span>
                                                                ) : (
                                                                    <span className="greenColor">
                                                                        {labels.PAYMENT_CONFIRMATION_SUCCESS}
                                                                    </span>
                                                                )
                                                            }
                                                            <br />
                                                        </Typography>
                                                        <br />
                                                        <Typography className={classes.h3Primary}>
                                                            <b>{labels.PAYMENT_CONFIRMATION_PAYMENT_DATE}</b>
                                                        </Typography>
                                                        <Typography className={classes.h3Primary}>
                                                            {postPaymentProcessing.mainData
                                                                ? commonService.dateFormat(
                                                                    element.paymentInfo.input.paymentDateTime,
                                                                    "MMM DD, YYYY"
                                                                )
                                                                : null}
                                                        </Typography>
                                                        <br />
                                                        <Typography className={classes.h3Primary}>
                                                            <b>
                                                                {labels.PAYMENT_CONFIRMATION_AMOUNT_PAID}
                                                            </b>
                                                        </Typography>
                                                        <Typography className={customStyle.amountTertiary}>
                                                            {postPaymentProcessing.mainData
                                                                ? element.paymentInfo.input.currency +
                                                                " " +
                                                                Number(parseFloat(element.paymentInfo.input.paymentAmount).toFixed(2)).toLocaleString('en', {
                                                                    minimumFractionDigits: 2
                                                                })
                                                                : null}
                                                        </Typography>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <hr />
                                        </React.Fragment>
                                    );
                                })
                                :
                                null}
                        </div>
                    </div>
                    <div>
                        <Hidden smUp>
                            {open &&
                                <div className="textPayment">
                                    <span className={customStyle.greyColor}>
                                        {labels.PAYMENT_CONFIRMATION_SUBTITLE}&nbsp;
                                        <Link to={routePaths.SELF_HELP} className={customStyle.linkColor}>
                                            <b> {labels.PAYMENT_CONFIRMATION_FAQ}</b>
                                        </Link>
                                    </span>
                                </div>
                            }
                        </Hidden>
                        <Hidden xlUp xsDown>
                            <div className="textPayment">
                                <span className={customStyle.greyColor}>
                                    {labels.PAYMENT_CONFIRMATION_SUBTITLE}&nbsp;
                                    <Link to={routePaths.SELF_HELP} className={customStyle.linkColor}>
                                        <b> {labels.PAYMENT_CONFIRMATION_FAQ}</b>
                                    </Link>
                                </span>
                            </div>
                        </Hidden>
                        <hr />
                        <div className={customStyle.maindivPayment}>
                            {postPaymentProcessing.mainData.length > 0
                                ? postPaymentProcessing.mainData.map((element, k) => {
                                    return (
                                        <React.Fragment key={k}>
                                            <Grid
                                                container
                                                direction="row"
                                                justify="space-between"
                                                alignContent="center"
                                            >
                                                <Grid item xs={12} md={6}>
                                                    <br />
                                                    <Typography className={classes.h2Primary}>
                                                        {postPaymentProcessing.mainData.length > 1 ? (
                                                            <b>
                                                                {labels.PAYMENT_CONFIRMATION_INFO_TITLE +
                                                                    `(${k + 1}/${postPaymentProcessing.mainData.length
                                                                    })`}
                                                            </b>
                                                        ) : (
                                                            <b>{labels.PAYMENT_CONFIRMATION_INFO_TITLE}</b>
                                                        )}
                                                    </Typography>
                                                    <br />
                                                    {postPaymentProcessing ? (
                                                        <Table size="small">
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell className={headStyle}>
                                                                        <b>{labels.PAYMENT_CONFIRMATION_INFO_TYPE}</b>
                                                                    </TableCell>
                                                                    <TableCell className={headStyle} align="left">
                                                                        {element.paymentInfo.input.paymentType}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell className={headStyle}>
                                                                        <b>
                                                                            {labels.PAYMENT_CONFIRMATION_INFO_CARD_TYPE}
                                                                        </b>
                                                                    </TableCell>
                                                                    <TableCell className={headStyle} align="left">
                                                                        {
                                                                            element.paymentInfo.input.creditCardInfo
                                                                                .cardType
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                                {element.paymentInfo.input.creditCardInfo
                                                                    .cardNumber && (
                                                                        <TableRow>
                                                                            <TableCell className={headStyle}>
                                                                                <b>
                                                                                    {
                                                                                        labels.PAYMENT_CONFIRMATION_INFO_CARD_NUMBER
                                                                                    }
                                                                                </b>
                                                                            </TableCell>
                                                                            <TableCell className={headStyle} align="left">
                                                                                {
                                                                                    element.paymentInfo.input.creditCardInfo
                                                                                        .cardNumber
                                                                                }
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                {element.paymentInfo.input.creditCardInfo
                                                                    .expMonth && (
                                                                        <TableRow>
                                                                            <TableCell className={headStyle}>
                                                                                <b>
                                                                                    {
                                                                                        labels.PAYMENT_CONFIRMATION_CARD_EXPIRY_DATE
                                                                                    }
                                                                                </b>
                                                                            </TableCell>
                                                                            <TableCell className={headStyle} align="left">
                                                                                {element.paymentInfo.input.creditCardInfo
                                                                                    .expMonth +
                                                                                    "/" +
                                                                                    element.paymentInfo.input.creditCardInfo
                                                                                        .expYear}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                {element.billingAddress &&
                                                                    <TableRow>
                                                                        <TableCell className={headStyle}>
                                                                            <b>{labels?.PAYMENT_BILLING_ADDRESS}</b>
                                                                        </TableCell>
                                                                        <TableCell className={headStyle} align="left">
                                                                            {element.billingAddress}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                }
                                                            </TableBody>
                                                            <br />
                                                        </Table>
                                                    ) : null}
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Card className={customStyle.lightblueColorPayment}>
                                                        <Typography className={classes.h3Primary}>
                                                            <b>
                                                                {element.head.connectionId
                                                                    ? connectionLabel
                                                                    : element.head.billId
                                                                        ? "Bill Id"
                                                                        : null}
                                                            </b>
                                                        </Typography>
                                                        <Typography className={classes.h3Primary}>
                                                            {element.head.connectionId
                                                                ? element.head.connectionId
                                                                : element.head.billId
                                                                    ? element.head.billId
                                                                    : null}
                                                            <br />
                                                        </Typography>
                                                        <br />
                                                        {element.errorInformation.isInError === "true" ? null : (
                                                            <React.Fragment>
                                                                <Typography className={classes.h3Primary}>
                                                                <b>{receiptLabel}</b>
                                                                </Typography>
                                                                <Typography className={classes.h3Primary}>
                                                                    {element.paymentInfo.inputOutput && element.paymentInfo.inputOutput.paymentEventId }
                                                                <br />
                                                                </Typography>
                                                                <br />
                                                            </React.Fragment>
                                                        )}
                                                        <Typography className={classes.h3Primary}>
                                                            <b>{labels.PAYMENT_CONFIRMATION_PAYMENT_STATUS}</b>
                                                        </Typography>
                                                        <Typography className={classes.h3Primary}>
                                                            {
                                                                element.errorInformation.isInError === "true" ? (
                                                                    <span className="redColor">
                                                                        Failure - {element.errorInformation.errorMessage}
                                                                    </span>
                                                                ) : (
                                                                    <span className="greenColor">
                                                                        {labels.PAYMENT_CONFIRMATION_SUCCESS}
                                                                    </span>
                                                                )
                                                            }
                                                            <br />
                                                        </Typography>
                                                        <br />
                                                        <Typography className={classes.h3Primary}>
                                                            <b>{labels.PAYMENT_CONFIRMATION_PAYMENT_DATE}</b>
                                                        </Typography>
                                                        <Typography className={classes.h3Primary}>
                                                            {postPaymentProcessing.mainData
                                                                ? commonService.dateFormat(
                                                                    element.paymentInfo.input.paymentDateTime,
                                                                    "MMM DD, YYYY"
                                                                )
                                                                : null}
                                                        </Typography>
                                                        <br />
                                                        <Typography className={classes.h3Primary}>
                                                            <b>
                                                                {labels.PAYMENT_CONFIRMATION_AMOUNT_PAID}
                                                            </b>
                                                        </Typography>
                                                        <Typography
                                                            className={customStyle.amountTertiary}
                                                            variant="h5"
                                                        >
                                                            {postPaymentProcessing.mainData
                                                                ? element.paymentInfo.input.currency +
                                                                " " +
                                                                Number(parseFloat(element.paymentInfo.input.paymentAmount).toFixed(2)).toLocaleString('en', {
                                                                    minimumFractionDigits: 2
                                                                })
                                                                : null}
                                                        </Typography>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                direction="row-reverse"
                                                justify="space-between"
                                                alignContent="center"
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                >
                                                    <hr />
                                                    {/* {lastContent ? (
                                                        <Typography
                                                            className={customStyle.greyColor}
                                                            dangerouslySetInnerHTML={{
                                                                __html: lastContent,
                                                            }}
                                                        />
                                                    ) : null} */}
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    );
                                })
                                : null}
                        </div>
                        <br />
                    </div>
                </div>

            )}
        </div>
    </Card>
    );
};


const connectedApp = connect(null, { accountDetailsWithApiCall })(GooglePayConfirmation);
export { connectedApp as GooglePayConfirmation };
