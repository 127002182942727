import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { config } from '../../../enviroment';
import { routePaths } from '../../config/route-paths';
import { Card } from "@material-ui/core"

const GPay3DSRedirectHandler = () => {
  const [statusMessage, setStatusMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log("Query params",queryParams)
    const TransactionId = queryParams.get('TransactionId');
    const MD = queryParams.get('MD');
    // window.top.location.href = `http://localhost:3000${routePaths.GOOGLE_PAY_3DS_RESULT_URL}?TransactionId=${TransactionId}&MD=${MD}`.replace(/([^:]\/)\/+/g, "$1");
    window.top.location.href = `${config.urls.BASE_URL}${routePaths.GOOGLE_PAY_3DS_RESULT_URL}?TransactionId=${TransactionId}&MD=${MD}`.replace(/([^:]\/)\/+/g, "$1");
  }, []);

  return (
    <Card style={{ height: "45vh"}}>
      <div style={{ textAlign: 'center', padding: '20px' }}>
        {loading && <h2>Redirecting...</h2>}
      </div>
    </Card>
  );
};

export default GPay3DSRedirectHandler;
